<template>
  <div>
    <el-table :data="showPlans" style="width: 100%">
      <el-table-column label="Plan" style="width:25%">
        <template slot-scope="scope">
          <b v-if="scope.row.subscription_plan.name === 'Free'">
            Basic
          </b>
          <b
            v-else-if="scope.row.subscription_plan.id === 6"
            class="test-color"
          >
            Education Max
          </b>
          <b v-else class="test-color">
            {{ scope.row.subscription_plan.name }}
          </b>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Type">
        <template slot-scope="scope">
          <b v-if="scope.row.subscription_plan_price">
            {{ scope.row.subscription_plan_price.nickname }}
          </b>
          <b v-if="scope.row.source === 'organization'">
            Deposit from
            <span class="text-success">Teacher</span>
          </b>
          <b v-if="scope.row.source === 'super_admin'">
            Deposit from
            <span class="text-success">Admin</span>
          </b>
          <b v-if="scope.row.source === 'Free'">
            Free
          </b>
        </template>
      </el-table-column> -->
      <el-table-column label="Price" v-if="!forAdmin" style="width:25%">
        <template slot-scope="scope">
          <b v-if="scope.row.subscription_plan_price"
            >${{ scope.row.subscription_plan_price.amount }}</b
          >
          <b v-else>Free </b>
        </template>
      </el-table-column>
      <el-table-column label="Validity period" style="width:50%">
        <template slot-scope="scope">
          <template v-if="scope.row.period_start && scope.row.period_end">
            <b>{{ moment(scope.row.period_start).format("YYYY-MM-DD") }}</b>
            ~
            <b>{{ moment(scope.row.period_end).format("YYYY-MM-DD") }}</b>
          </template>
          <b v-if="scope.row.source === 'Free'">Free Forever</b>
        </template>
      </el-table-column>
      <el-table-column label="Upgrade" v-if="!forAdmin">
        <template slot-scope="scope">
          <router-link
            v-if="onlyBasic"
            :to="{
              name: role === 'educator' ? 'EducatorPlans' : 'StudentPlans'
            }"
          >
            <el-button type="success" size="mini">
              <i class="fa fa-arrow-up"></i>
              Upgrade
            </el-button>
          </router-link>
          <el-button
            v-else-if="onlyPro"
            type="success"
            size="mini"
            @click="handleUpgradeToMax(scope.row)"
          >
            <i class="fa fa-arrow-up"></i>
            Upgrade to Max Plan
          </el-button>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <!-- <el-table-column label="Next subscription date" v-if="!forAdmin">
        <template slot-scope="scope">
          <template v-if="scope.row.cancel_at_period_end === 0">
            <b>
              {{ moment(scope.row.period_end).format("YYYY-MM-DD") }}
            </b>
          </template>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="Auto-renewal" v-if="!forAdmin">
        <template slot-scope="scope">
          <template v-if="scope.row.subscription_plan_price">
            <div v-show="!scope.row.cancel_at_period_end">
              <el-button
                type="danger"
                size="mini"
                plain
                @click="handleAutoRenewalChange(scope.row)"
              >
                <i class="fa fa-ban"></i>
                Cancel Auto-renewal
              </el-button>
            </div>
            <div v-show="scope.row.cancel_at_period_end">
              <el-button
                type="primary"
                size="mini"
                plain
                @click="handleAutoRenewalChange(scope.row)"
              >
                <i class="fa fa-refresh"></i>
                Enable Auto-renewal
              </el-button>
            </div>
          </template>
          <span v-else>-</span>
        </template>
      </el-table-column>
      -->
    </el-table>
    <el-dialog
      :visible.sync="showMaxTypeDialog"
      :width="isPhone ? '95%' : '80%'"
      class="p-0"
      v-if="upgradeInfo"
    >
      <h4 slot="title">
        <div class="plans-title plan-max-button">
          Max Plan
        </div>
      </h4>
      <div>
        <el-card>
          <template>
            <div class="plan-options">
              <b>Key Features:</b>
              <div
                class="plan-option"
                v-for="(item, index) in maxList"
                :key="index"
              >
                <span class="icon">
                  <i class="fa fa-check"></i>
                </span>
                <span>
                  <span v-show="index > 8">
                    <b class="logo-color mr-1">AI</b>
                  </span>
                  <span v-html="item"></span>
                </span>
              </div>
            </div>
            <hr />
            <div>
              <div class="invoice-table">
                <div class="invoice-header">
                  <span class="description">Description</span>
                  <span class="qty">Date</span>
                  <span class="amount">Amount</span>
                </div>

                <div class="invoice-row">
                  <div class="description">
                    <div>Remaining time on Max Plan</div>
                  </div>
                  <div class="qty">
                    <div class="date-range">
                      {{ moment().format("YYYY-MM-DD") }} ~
                      {{ moment(upgradeInfo.period_end).format("YYYY-MM-DD") }}
                    </div>
                  </div>
                  <div class="amount">
                    ${{ formatNumber(upgradeInfo.remaining_amount) }}
                  </div>
                </div>
                <div class="invoice-row">
                  <div class="description">
                    <div>Unused time on Pro Plan</div>
                  </div>
                  <div class="qty">
                    <div class="date-range">
                      {{ moment().format("YYYY-MM-DD") }} ~
                      {{ moment(upgradeInfo.period_end).format("YYYY-MM-DD") }}
                    </div>
                  </div>
                  <div class="amount">
                    -${{ formatNumber(upgradeInfo.unused_amount) }}
                  </div>
                </div>
                <div class="text-right">
                  <div
                    class="plan-fee money-text max-color"
                    style="font-size: 3rem;"
                  >
                    ${{ formatNumber(upgradeInfo.total) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <hr />
          <div class="text-right">
            <el-button type="primary" @click="upgradeNow()">
              <i class="fa fa-arrow-up"></i>
              Upgrade to Max Plan
            </el-button>
          </div>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import moment from "moment";
import Plans from "@/views/profile/apis/plans";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: ["myPlans", "forAdmin", "role"],
  data() {
    return {
      moment,
      showMaxTypeDialog: false,
      upgradeInfo: null,
      maxList: [
        "<b>Unlimited Access</b> to All Exams",
        "100% Replica of the Bluebook/TestNav Platform",
        "<b>20</b> Full Length SATs",
        "<b>40</b> Full Length ACTs",
        "<b>20</b> Full Length APs (Coming Soon)",
        "<b>6</b> Full Length PSATs (Coming Soon)",
        "<b>50</b> Full Length TOEFLs (Coming Soon)",
        "<b>40</b> Full Length IELTSs (Coming Soon)",
        "Immediate Scoring",
        "<b>Unlimited</b> Interactive Answer Explanations ",
        "Time Tracking & Proctoring",
        "Performance Analysis",
        "Intelligent Flash Cards",
        "Smart Score Projector",
        "Focused Practice Drills",
        "Powered Study Plan"
      ]
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    showPlans() {
      let showPlan = [];
      if (this.myPlans.length === 0) {
        showPlan = JSON.parse(JSON.stringify(this.myPlans));
        showPlan.unshift({
          subscription_plan: {
            name: "Free"
          },
          source: "Free",
          period_start: null,
          period_end: null
        });
      } else {
        showPlan = JSON.parse(JSON.stringify(this.myPlans));
      }
      return showPlan;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    onlyBasic() {
      if (this.myPlans.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    onlyPro() {
      if (this.myPlans.length > 0) {
        let isPro = true;
        let max = 0;
        this.myPlans.forEach(plan => {
          if (
            plan.subscription_plan.key_name === "max_education" ||
            plan.subscription_plan.key_name === "max"
          ) {
            max++;
          }
        });
        if (max > 0) {
          isPro = false;
        }
        return isPro;
      } else {
        return false;
      }
    }
  },
  watch: {},

  mounted() {},

  methods: {
    async upgradeNow() {
      try {
        // 先关闭当前对话框，确保确认框能够正常显示
        this.showMaxTypeDialog = false;
        // 使用 nextTick 确保 DOM 更新后再显示确认对话框
        this.$nextTick(() => {
          this.$confirm(
            "Are you sure you want to upgrade to Max plan?",
            "Confirmation",
            {
              confirmButtonText: "Confirm",
              cancelButtonText: "Cancel",
              type: "warning"
            }
          )
            .then(async () => {
              const loading = this.$loading({
                lock: true,
                text: "Processing your upgrade request...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
              });

              try {
                // Call the upgrade API
                const res = await Plans.upgradeToMax(this.upgradeInfo.planId, {
                  success_url: `${baseDomainPath}student/profile?active=plans`
                });
                if (res.url) {
                  window.location.href = res.url;
                } else {
                  this.loading = false;
                }
              } catch (error) {
                this.$message.error("Upgrade failed, please try again later");
              } finally {
                loading.close();
              }
            })
            .catch(() => {
              // User canceled the operation
              // 如果用户取消，重新打开详情对话框
              this.showMaxTypeDialog = true;
            });
        });
      } catch (error) {
        this.$message.error("Operation failed, please try again later");
        console.error("Operation error:", error);
      }
    },
    async handleAutoRenewalChange(plan) {
      try {
        // 根据当前状态确定操作文本（启用或取消）
        const actionText = plan.cancel_at_period_end ? "Enable" : "Cancel";
        // 弹出确认对话框
        await this.$confirm(
          `Are you sure you want to ${actionText} auto-renewal?`,
          "Confirm",
          {
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning"
          }
        );

        // 根据当前状态执行相应操作
        if (!plan.cancel_at_period_end) {
          // 取消自动续订
          await Plans.cancelAuto(plan.id);
        } else {
          // 启用自动续订
          await Plans.enableAuto(plan.id);
        }
        // 切换自动续订状态
        // plan.cancel_at_period_end = !plan.cancel_at_period_end;
        // 显示成功消息
        await this.$emit("getPlans");
        this.$message({
          type: "success",
          message: `Auto-renewal has been ${actionText.toLowerCase()}d`
        });
      } catch (error) {
        // 如果错误不是用户取消操作，则显示错误消息
        if (error !== "cancel") {
          this.$message.error("Operation failed, please try again later");
        }
      }
    },
    async handleUpgradeToMax(plan) {
      this.$emit("handleUpgradeToMax");
      const res = await Plans.getUpgradeInfo(plan.id);
      this.showMaxTypeDialog = true;
      this.upgradeInfo = {
        ...res,
        planId: plan.id,
        period_end: plan.period_end
      };
    },
    formatNumber(number) {
      // 檢查是否為有效數字
      if (isNaN(number)) return number;
      // 將數字轉換為字符串並檢查是否包含小數點
      const str = number.toString();
      if (str.includes(".")) {
        // 如果有小數，則固定顯示兩位小數
        return Number(number).toFixed(2);
      }
      // 如果沒有小數，直接返回原數字
      return str;
    }
  }
};
</script>

<style scoped>
.free-color {
  color: #01b884;
}
.plan-options {
  font-size: 14px;
}
.plan-option {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin: 10px 0;
}
.plan-option .icon {
  font-weight: 700;
  margin-right: 0.8rem;
}
.plans-title {
  font-size: 1rem;
  color: white;
  display: inline-block;
  line-height: 2rem;
  padding: 0 1rem;
  margin-left: 0.5rem;
  border-radius: 1rem;
}
.plan-max-button {
  background-color: #1f7cff;
}
.plan-fee {
  font-size: 4rem;
  font-weight: 700;
}
.max-color {
  color: #1f7cff;
}
::v-deep .el-dialog__body {
  padding-top: 0;
}
.upgrade-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-header {
  display: flex;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  font-weight: bold;
}

.invoice-row {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.description {
  flex: 3;
  text-align: left;
}

.date-range {
  color: #666;
  margin-top: 5px;
}

.qty {
  flex: 1;
  text-align: center;
}

.unit-price {
  flex: 1;
  text-align: right;
}

.amount {
  flex: 1;
  text-align: right;
}

.invoice-footer {
  margin-top: 20px;
}

.subtotal-row,
.total-row,
.amount-due-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.amount-due-row {
  font-weight: bold;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
}
</style>
