import ApiService from "@/common/api.service";

export default {
  cancelAuto(subscriptionId) {
    return ApiService.post(`/subscriptions/${subscriptionId}/cancel`);
  },
  enableAuto(subscriptionId) {
    return ApiService.post(`/subscriptions/${subscriptionId}/resume`);
  },
  getUpgradeInfo(subscriptionId){
    return ApiService.query(`/subscriptions/${subscriptionId}/upgrade-info`);
  },
  upgradeToMax(subscriptionId, data) {
    return ApiService.post(`/subscriptions/${subscriptionId}/upgrade`, data);
  }
};
